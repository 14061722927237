import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Hello Working From day calculator");
        let variance = parseFloat(document.getElementById('variance').innerText);
        console.log(variance)
        window.addEventListener('input', () => {
            let crew_size = document.getElementById('crewSize').value;
            let bid_hours = document.getElementById('hours').value;
            let work_day_hours = document.getElementById('dayHours').value;
            console.log(crew_size)
            console.log(bid_hours)
            console.log(work_day_hours)
            let value = (variance * (bid_hours / work_day_hours) / crew_size) + ((bid_hours / work_day_hours) / crew_size)
            document.getElementById('days').value = Math.ceil(value);
        })
    }
}