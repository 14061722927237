import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["content", "backdrop"]

    open() {
        this.contentTarget.classList.add("block")
        this.backdropTarget.classList.add("block")
    }

    close() {
        this.contentTarget.classList.remove("block")
        this.backdropTarget.classList.remove("block")
    }
}