import {Application} from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export {application}
// FusionCharts.options.license({
//     key: 'xjB3fB-8qH2B7B2A5F4C4G5D3A3H3C11A5C7vaiD4F1nhfxiD5D3A-8teB6A6E3E5B4E4E3F2I3A8A4E5B4D1D3G4poiB9D2C6iG4F4D3G-7B-31jA5C5E3tpgA1C3A4tllG2F4H1C2C6D5C5A3E6D4G4ckeC6D4PF4kmI-8F2C2D5uf1C2KC1C1G-7B-21oC1E5C1B3H3E2E2C7A3A2C4D2C1C3w==>',
//     creditLabel: false,
// });